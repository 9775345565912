.intro-section{
  margin-top: -31.25rem;
  padding-bottom:10rem;

  @include media-breakpoint-down(sm) {
    padding-left:1.5rem;
    padding-right:1.5rem;
  }

  .intro-img{
    border: 0.75rem solid $st-blue;
    box-shadow: $box-shadow-lg;
    margin-bottom: 2rem;

  }

}
