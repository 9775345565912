#header{

	background-color: $st-blue;

	.homepage-nav{
		padding-top: 2rem;
		text-align: center;

		.logo{
			display: inline-block;

			img{
				width: 18.5625rem;
			}
		}

		ul.nav-links{
			text-align: center;
			padding-top:2rem;
			padding-bottom:1.5rem;
			margin: 0;

			li{
				display: inline-block;
				padding:1rem;

				a{
					color: white;
					text-decoration: none;
					font-weight: 700;
					font-size: 1rem;
					line-height: 1.3125rem;
					text-transform: uppercase;
					border-bottom: 2px solid $st-blue;
					padding-bottom: 0.5rem;
					transition: all 0.2s ease-in-out;

					&:hover{
						color:$st-green;
						border-bottom: 2px solid $st-green;
					}

				}
			}
		}

	}

	.main-nav{

		position: fixed;
		top: 0;
		z-index: 900;
		box-shadow: $box-shadow-lg;

		background-color: $st-blue;


		.navbar-slim {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		padding-bottom: 1rem;

		.navbar-brand{

			img{
				width: 4rem;
			}

		}

		.menu-icon {
			position: relative;
			width: 3.125rem;
			height: 3.125rem;
			cursor: pointer;

			.menu-icon__cheeckbox {
				display: block;
				width: 100%;
				height: 100%;
				cursor: pointer;
				z-index: 2;
				-webkit-touch-callout: none;
				position: absolute;
				opacity: 0;
			}

			div {
				margin: auto;
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
				width: 2.1875rem;
				height: 0.75rem;
			}

			span {
				position: absolute;
				display: block;
				width: 100%;
				height: 0.125rem;
				background-color: white;
				border-radius: 0.0625rem;
				transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

				&:first-of-type {
					top: 0;
				}

				&:last-of-type {
					bottom: 0;
				}
			}

			&.active,
			.menu-icon__cheeckbox:checked + div {
				span {
					&:first-of-type {
						transform: rotate(45deg);
						top: 0.3125rem;
					}

					&:last-of-type {
						transform: rotate(-45deg);
						bottom: 0.3125rem;
					}
				}
			}

			&.active:hover span:first-of-type,
			&.active:hover span:last-of-type,
			&:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
			&:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
				width: 2.1875rem;
			}

			&:hover {
				span:first-of-type {
					width: 2.5rem;
				}

				span:last-of-type {
					width: 1.5625rem;
				}

			}
		}


		#menu{
			height: calc(100vh - 5.5rem);
			overflow-y: scroll;
			ul{
				margin-top: 1.5rem;
				li{
					&:last-child{
						border-bottom: 0.1rem solid $st-gray-03;
					}

					.nav-link{
						@include h3;
						color: white;
						border-top: 0.1rem solid $st-gray-03;
						padding-left: 0;
						padding-top: 1rem;
						padding-bottom: 1rem;
						transition: all 0.2s ease-in-out;

						&:hover{
							color:$primary;
							padding-left: 1rem;
						}

					}

				}
			}
		}


	}
	}


}
