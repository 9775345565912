.pageTimberSpecies{

  .material-row{
    position: relative;
    margin-bottom: 10rem;

    &:last-child{
      margin-bottom: 0;
    }

    .material-name{
      @include display4;
      margin-left: 12.5rem;
    }

    .material-img{
      border: 0;
      box-shadow: $box-shadow-lg;
      position: absolute;
      top: 0;
      left: 2rem;
    }

    hr{
      height: 0.2rem;
      background-color: $st-green;
      opacity: 1;
    }

    p{
      margin-left: 12.5rem;
    }

    .spec-data{
      width: 10.9375rem;
      height: 10.9375rem;
      background-color: rgba($st-green,0.8);
      border-radius: 100%;
      text-align: center;
      padding: 2rem;
      display: inline-grid;
      position: relative;
      margin-left: 2rem;
      margin-bottom: 3.5rem;

      .icon{
        border: 0;
        box-shadow: none;
        margin-bottom: 0;
        margin-top: -5rem;

      }

      dd{
        @include h2;
        margin-bottom: 0;
      }

      dt{
        @include p;
        line-height: initial;
      }

    }

  }

}