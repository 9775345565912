#general-page{

  .pagecontent{

    img{
      width: 100%;
    }

  }

}