#footer{

	background-color: $st-dark-alt;
	padding-top: 4rem;
	padding-bottom: 4rem;

	.subscribe-block{
		justify-content: center;
		text-align: center;
		padding:1rem 0 4rem;

		.subscribe-title{
			@include h1;
			color:$white;
			margin-bottom: 2rem;
		}

		input{
			text-align: center;
			margin-bottom: 1.5rem;

			&:focus + label, &:not(:placeholder-shown) ~ label{
				font-size: 0.75em;
				transform: scale(1) translateY(-0.75rem) translateX(0rem);
			}

		}

		label{
			right: 0!important;
			text-align: center;
			color:#A1AEB7;



		}

		input[type=submit]{
			width: 100%;
			max-width: 15rem;
		}

	}

	.footer-pages{
		ul{
			@include h4;
			padding: 0;
			text-align: center;

			li{
				display: inline-block;
				padding: 1rem;

				a{
					text-decoration: none;
					color: white;
					transition: color 0.2s ease-in-out;

					&:hover{
						color:$st-green;
					}

				}
			}
		}
	}

	.footer-social{
		ul{
			text-align: center;
			padding:0;

			li{
				display: inline-block;
				padding: 0.15rem;
			}
		}
	}

	.footer-copyright{
		color: white;
		@include small;

		p{
			text-align: center;
			@include small;
			margin-bottom: 0;

		}
		ul{
			text-align: center;

			li{
				display: inline-block;
				padding: 0 1rem;

				a{
					text-decoration: none;
					text-transform: uppercase;
					color: white;
					transition: color 0.2s ease-in-out;

					&:hover{
						color: $st-green;
					}

				}
			}
		}
	}

}
