.lightbox{
  z-index:9999;

  .carousel-control-prev, .carousel-control-next{
    background-color: transparent;
    border: 0;
  }

  .lightbox-caption{
    background-color: $st-green;
    color: $st-dark!important;
    font-size: 1rem!important;
  }

}