.pageAbout {

  #main-block{
    overflow-y: visible;
  }

  .team-section {

    margin-bottom: 7rem;

    &-title {
      @include display2;
      margin-top: -3rem;
      margin-left: -3rem;
      margin-bottom: 3rem;

      @include media-breakpoint-down(lg) {
        @include display3;
        margin-top: -1.5rem;
        margin-left: 2rem;
        margin-bottom: 1rem;
      }

      @include media-breakpoint-down(sm) {
        @include display4;
      }
    }

    .team-row{

      margin: 0 -5rem;

      @include media-breakpoint-down(lg) {
        margin: 0;
      }

      .team-entry{
        margin-bottom: 2rem;

        .team-img{}
        .team-name{
          @include h4;
        }
        .team-job{
          @include lead;
        }

      }

    }

  }

}