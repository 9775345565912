::selection{background-color: transparentize($st-green, 0.5);}

.display-1{ @include display1; }
.display-2{ @include display2; }
.display-3{ @include display3; }
.display-4{ @include display4; }

h1, .h1{ @include h1; }
h2, .h2{ @include h2; }
h3, .h3{ @include h3; }
h4, .h4{ @include h4; }
h5, .h5{ @include h5; }
h6, .h6{ @include h6; }

.lead{ @include lead;}

p, .p{ @include p;}

.small{ @include small}

label{ @include label }