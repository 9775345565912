.news{

  .hero-section{
    padding-left: 0;
  }

  #news-content{

    background-color: $st-dark;
    box-shadow: $box-shadow-lg;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .news-hr{
      background-color: $st-green;
      height: 0.1875rem;
      opacity: 1;
      margin-bottom: 2rem;
    }

    .news-title{
      @include display3;
    }

    .news-image{
      width: 100%;
      margin-bottom: 2rem;
    }

  }

  .controls{
    margin-top: 2rem;
    font-size: 1.25em;
    text-align: center;

    a{
      text-decoration: none;
    }
  }

}
