.pageHome{

	#main-block{
		@include media-breakpoint-down(md) {
			margin-top: 6.5rem;
		}
	}

	#header{

		@include media-breakpoint-up(md) {

			.main-nav {
					transition: top 0.5s ease-in-out;
					top: -10rem;
				&.active{
					top: 0;
				}
			}

		}

	}


	.hero-section{

		max-width: $st-max-width;
		justify-self: center;

		margin-left: auto;
		margin-right: auto;
		margin-top: 0;
		padding: 10rem 0 0;

		background-image: url("/img/bg/home-hero-bg.png");
		background-size: cover;
		background-position: top right;
		background-attachment: fixed;

		@include media-breakpoint-down(md) {
			padding-top: 5rem;
		}

		@include media-breakpoint-down(sm) {
			padding-left: 1rem;
		}

		&:after{
			background-image: url('/img/misc/tri.svg');
			background-repeat: no-repeat;
			background-size: contain;
			display:block;
			width: 100%;
			height: 31.25rem;
			content:"";
			margin-top: 10rem;
		}

		.title{
			color: white;

			@include media-breakpoint-down(lg) {
				@include display3;
			}

			@include media-breakpoint-down(sm) {
				@include display4;
			}

		}

		a:last-child{
			margin-left: 1rem;
			@include media-breakpoint-down(sm) {
				margin-left: 0;
				margin-top: 0;
			}
		}


	}


	.auto-section{
		margin-top: -5rem;
		padding-bottom:10rem;

		@include media-breakpoint-down(sm) {
			padding-left:1.5rem;
			padding-right:1.5rem;
		}

		.intro-img{
			border: 0.75rem solid $st-blue;
			box-shadow: $box-shadow-lg;
			margin-bottom: 2rem;

		}

	}

	.range-section{

		margin-bottom: -25rem;

		&-title{
			@include display2;
			margin-top: -3rem;
			margin-left: -3rem;

			@include media-breakpoint-down(lg) {
				@include display3;
				margin-top: -1.5rem;
				margin-left:2rem;
				margin-bottom: 1rem;
			}

			@include media-breakpoint-down(sm) {
				@include display4;
			}
		}

		.range-entry{

			align-items: center;
			margin:3rem 0;

			.range-img{
				img {
					box-shadow: $box-shadow;
					width: 150%;
					margin-left: -50%;
					@include media-breakpoint-down(lg) {
						width: 100%;
						margin-left: 0;
					}
				}
			}
			.range-info{
				padding: 2rem;

				&-title{
					@include h1;
					@include media-breakpoint-down(sm) {
						@include h3;
					}
				}

				.btn{
					@include media-breakpoint-down(sm) {
						width: 100%;
					}
				}

			}

			&:nth-child(2n+1){
				flex-direction: row-reverse;
				.range-img{
					img {
						margin-left: 0;
					}
				}

			}


		}
	}



	.family-section{

		margin-top: -25rem;
		margin-bottom: 20rem;

		&-title{
			@include display2;
			margin-top: -3rem;
			margin-left: -3rem;
			margin-bottom: 2rem;

			@include media-breakpoint-down(lg) {
				@include display3;
				margin-top: -1.5rem;
				margin-left:1rem;
				margin-bottom: 1rem;
			}
			@include media-breakpoint-down(sm) {
				@include h1;
			}
		}

		&-img{
			width: 120%;
			margin-left: -20%;
			margin-bottom: -20%;

			@include media-breakpoint-down(lg) {
				width: 100%;
				margin: 0;
				padding: 1rem;
			}
		}

		&-info{
			padding: 2rem;
		}

	}

	.news-section{

		max-width: $st-max-width;
		margin-left:auto;
		margin-right:auto;
		margin-bottom: 2rem;
		padding-bottom: 2rem;
		background-color: $st-dark-alt;


		&-title{
			@include display4;
			text-align: center;
			margin-top: -1.75rem;
		}

		&-btn{
			padding-left: 4rem;
			padding-right: 4rem;
		}


	}

}
