// Reset -------------------------------------------------
//
// This is to reset any global styling after bootstrap.
//
// -------------------------------------------------------


body{
	background-color: $st-blue;
	color: white;
	overflow-x: hidden;
}

.container-fluid{
	padding-left: 2rem;
	padding-right: 2rem;
}