.form-control{
	border-radius: 0;

	&-dark, &-dark:focus{
		color:#A1AEB7;
		background-color: $st-dark;
		border-color: lighten($st-dark, 20%);

		&::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder{
			color:#A1AEB7!important;
		}

	}

}
