.btn{
	margin-bottom: 0.3125rem;
	text-transform: uppercase;
	font-weight: 700;
	padding: 0.75rem;
	border-radius: 0;
	width: initial;


	&-sm{
		font-size: 0.8125rem;
		padding: 0.75rem;
	}
	&-lg{
		font-size: 1.125rem;
		padding: 1rem;
	}

	&-primary{
		color:$st-dark-alt;
	}

	&-secondary{
		color: $st-green;
		background-color: $st-dark-alt;

		&:hover{
			&:after {
				color: $white;
			}
		}
	}

	&-outline-primary{
		color:white;
		background-color:$st-dark-alt;
		border:0.125rem solid $st-green;

		&:hover{
			&:after {
				color: $st-dark;
			}
		}

	}

	&-arrow{

		&:after{
			font-family: "FontAwesome";
			content: "\f061";
			display: inline-block;
			padding-left: 0.8rem;
			color: $st-green;

		}

	}

}




// Social Buttons


.btn-social {
	border-radius: 50%;
	color: #ffffff !important;
	display: inline-block;
	height: 3.375rem;
	line-height: 3.375rem;
	background-color: lighten($st-dark, 5%);
	margin: 0.5rem 0.25rem;
	text-align: center;
	text-decoration: none;
	transition: background-color .3s;
	webkit-transition: background-color .3s;
	width: 3.375rem;

	.fa, i{
		backface-visibility: hidden;
		moz-backface-visibility: hidden;
		ms-transform: scale(1);
		o-transform: scale(1);
		transform: scale(1);
		transition: all .25s;
		webkit-backface-visibility: hidden;
		webkit-transform: scale(1);
		webkit-transition: all .25s;
	}

	&:hover, &:focus{
		color: #fff;
		background-color: $st-green;
		outline: none;
		text-decoration: none;

		.fa, i{
			ms-transform: scale(1.3);
			o-transform: scale(1.3);
			transform: scale(1.3);
			webkit-transform: scale(1.3);
		}

	}

	&.btn-sm
	{
		font-size: 0.8125rem;
		height: 2.25rem;
		line-height: 0.9375rem;
		margin: 0.375rem 0.125rem;
		width: 2.25rem;
	}
	&.btn-lg
	{
		font-size: 1.375rem;
		height: 4.5rem;
		line-height: 2.5rem;
		margin: 0.625rem 0.375rem;
		width: 4.5rem;
	}

}

.pagination{

	justify-content: center;

	.current{
		background-color: $primary;
		color: $secondary!important;
	}

}





