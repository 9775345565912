.clearance-card{

  background-color: $st-dark;
  box-shadow: $box-shadow;
  border:none;
  border-bottom: 0.3125rem solid $st-dark;
  transition: all 0.25s ease-in;
  overflow: hidden;

  &:hover{
    transform: translateY(-0.3125rem);
    border-bottom: 0.3125rem solid $st-green;

    .card-img{
      transform: scale(1.1);
    }

  }

  .img-col{
    overflow: hidden;

    .card-img{
      width: 100%;
      transition: all .4s ease;
    }

  }

  .card-body{

    .card-ref{@include h4;color:$st-green;}
    .card-title{@include h3;}
    .card-text{}
    .card-price{@include h4;}
    a{text-decoration: none;}

  }

}