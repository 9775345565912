// Fonts -------------------------------------------------
//
// This is for declaring web fonts ready to be passed
// down the stack.
//
// -------------------------------------------------------
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


@font-face {
	font-family: "Facundo";
	font-weight: 700;
	font-style: italic;
	src: url('/webFonts/FacundoBoldIt/font.woff2') format('woff2'), url('/webFonts/FacundoBoldIt/font.woff') format('woff');
}
@font-face {
	font-family: "Facundo";
	font-weight: 700;
	font-style: normal;
	src: url('/webFonts/FacundoBold/font.woff2') format('woff2'), url('/webFonts/FacundoBold/font.woff') format('woff');
}
@font-face {
	font-family: "Facundo";
	font-weight: 600;
	font-style: italic;
	src: url('/webFonts/FacundoSemiBoldIt/font.woff2') format('woff2'), url('/webFonts/FacundoSemiBoldIt/font.woff') format('woff');
}
@font-face {
	font-family: "Facundo";
	font-weight: 600;
	font-style: normal;
	src: url('/webFonts/FacundoSemiBold/font.woff2') format('woff2'), url('/webFonts/FacundoSemiBold/font.woff') format('woff');
}
@font-face {
	font-family: "Facundo";
	font-weight: 400;
	font-style: normal;
	src: url('/webFonts/FacundoRegular/font.woff2') format('woff2'), url('/webFonts/FacundoRegular/font.woff') format('woff');
}
@font-face {
	font-family: "Facundo";
	font-weight: 400;
	font-style: italic;
	src: url('/webFonts/FacundoRegularIt/font.woff2') format('woff2'), url('/webFonts/FacundoRegularIt/font.woff') format('woff');
}


@mixin display1{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		5.9375rem;
	line-height:	5.875rem;
	text-transform: uppercase;
}

@mixin display2{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		4.4375rem;
	line-height:	5.0625rem;
	text-transform: uppercase;
}

@mixin display3{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		3.4375rem;
	line-height:	4.0625rem;
	text-transform: uppercase;
}

@mixin display4{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		2.5rem;
	line-height:	3.125rem;
	text-transform: uppercase;
}

@mixin h1{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		1.75rem;
	line-height:	2.375rem;
	text-transform: uppercase;
}

@mixin h2{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		1.5rem;
	line-height:	2rem;
	text-transform: uppercase;
}

@mixin h3{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		1.3125rem;
	line-height:	1.9375rem;
	text-transform: uppercase;
}

@mixin h4{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		1rem;
	line-height:	1.3125rem;
	text-transform: uppercase;
}

@mixin h5{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		0.85rem;
	line-height:	1.15rem;
	text-transform: uppercase;
}

@mixin h6{
	font-family: 	Facundo, sans-serif;
	font-weight: 	700;
	font-size: 		0.75rem;
	line-height:	1rem;
	text-transform: uppercase;
}

@mixin lead{
	font-family: 	Facundo, sans-serif;
	font-weight: 	400;
	font-size: 		1rem;
	line-height:	1.875rem;
}

@mixin p{
	font-family: 	Facundo, sans-serif;
	font-weight: 	400;
	font-size: 		0.9375rem;
	line-height:	1.6875rem;
}

@mixin small{
	font-family: 	Facundo, sans-serif;
	font-weight: 	600;
	font-size: 		0.625rem;
	line-height:	1.375rem;
	text-transform: uppercase;
}

@mixin label{
	font-family: 	Facundo, sans-serif;
	font-weight: 	600;
	font-size: 		0.9375rem;
	line-height:	1.25rem;
}
