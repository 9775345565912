.pageOurGates, .gateEntries {

  .hero-section{
    margin-bottom: -30rem;

    &:after{
        margin-top: 3rem!important;
    }

  }

  #range-nav{
    box-shadow: $box-shadow-lg;
    background-color: $st-blue;
    padding: 0;

    ul{
      list-style: none;
      margin: 0;
      padding: 1rem;

      li{
        a{
          display: inline-block;
          text-decoration: none;
          color: white;
          padding-top: 1rem;
          padding-right: 1rem;
          padding-left: 1rem;
          padding-bottom: 0.3rem;
          @include h4;
          width: 100%;
          transition: all 0.25s ease-in-out;

          &.active{
            color:$st-green;
          }

          &:hover{
            color: $st-green;
            padding-left: 1.5rem;
          }

        }
      }

      li.level2{

        a{
          @include h5;
          font-weight:500;
          padding-top: 0.3rem;
          padding-right: 1rem;
          padding-left: 1rem;
          padding-bottom: 0.3rem;

          &:hover{
            padding-left: 1.5rem;
          }
        }

      }

    }


  }

  .range-entry{
    border-radius: 0;
    margin-bottom: 5rem;
    background-color: transparent;
    border: 0;


    .range-img{
      box-shadow: $box-shadow-lg;
      display: inline-block;
    }

    .range-description{
      display: inline-block;
      width:calc(100% - 4rem);
      box-shadow: $box-shadow-lg;
      background-color: $st-blue;
      margin-left: 2rem;
      margin-right: 2rem;
      margin-top: -5rem;
      padding: 1.5rem 2rem;

      .range-name{
        @include h3;
      }
      .range-txt{
        @include p;
        margin-bottom: 1rem;
      }

    }


  }

  .gallery-entry{
    margin: 1rem 0rem 0.5rem;
    overflow: hidden;

    &:hover{
      border-color: $st-green;
    }

  }

}