.pageContact{

  .intro-section{
    padding-bottom: 0;
  }

  #get-quote{
    padding-top: 10rem;

    .row:first-child{
      background-color: $st-dark;
      box-shadow: $box-shadow-lg;
      margin-bottom: 5rem;
    }

    input, select{
      text-align: left;
      margin-bottom: 1.5rem;
      padding-top: 1.5rem;

      &:focus + label, &:not(:placeholder-shown) ~ label{
        font-size: 0.75em;
        transform: scale(1) translateY(-0.75rem) translateX(0rem);
      }

    }

  }

}
