.rangeEntries{

  .hero-section{

    max-width: $st-max-width;
    justify-self: center;

    margin-left: auto;
    margin-right: auto;
    margin-top: 7rem;
    padding: 7rem 0 0;

    background-image: url("/img/bg/home-hero-bg.png");
    background-size: cover;
    background-position: top right;
    background-attachment: fixed;

    @include media-breakpoint-down(md) {
      padding-top: 5rem;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
    }

    &:after{
      background-image: url('/img/misc/tri.svg');
      background-repeat: no-repeat;
      background-size: contain;
      display:block;
      width: 100%;
      height: 31.25rem;
      content:"";
      margin-top: 10rem;
    }

    .title{
      color: white;
      &:after{
        content: "";
        display: inline-block;
        width: 100%;
        height: 0.2rem;
        background-color: $st-green;
      }

      @include media-breakpoint-down(lg) {
        @include display3;
      }

      @include media-breakpoint-down(sm) {
        @include h1;
      }

    }

    .sub-title{
      color: white;
      @include lead;

      @include media-breakpoint-down(sm) {
        @include lead;
      }


    }


  }


  .slider-controls{

      margin-top: 2.5rem;

      .carousel-control-prev, .carousel-control-next{
        display: inline-block;
        position: relative;
        font-family: "Font Awesome 5 Pro";
        font-size: 1.6rem;
        color: white;
        border:none;
        background-color: $st-dark-alt;
        width: 5rem;
        height: 5rem;
        opacity: 1;
        transition: background-color 0.25s ease-in;

        &:hover{
          background-color: $st-green;
        }

      }

      .carousel-control-prev{
        &:after{
          content: "\f177";
          color: white;
        }
        &:hover{
          & + .carousel-control-next{
            background-color: $st-dark-alt;
          }
        }
      }

      .carousel-control-next{
        background-color: $st-green;
        &:after{
          content: "\f178";
          color: white;
        }
      }

      .slider-description{
        border-left: solid $gray-600 1px;
        margin-left: 2rem;
        padding-left: 2rem;
        display: inline-block;
      }

    }


#indicatorcarousel{

  .carousel-inner{
    margin-top: -3.5rem;

    .carousel-item{

      button{
        width: calc(20% - 2rem);
        display: inline-block!important;
        border:0.3rem solid transparentize($st-green, 1);
        font-size: 0;
        margin: 0 2rem 0 0;
        padding: 0;
        &:focus{
          outline: 0;
        }
        &.active{
          border:0.3rem solid $st-green;
        }
        &:last-child{
          margin: 0;
        }

        img{
          width: inherit;
          margin: 0;
        }

      }

    }
  }
}


  .promise-section{

    margin-top: -25rem;
    margin-bottom: -20rem;

    &-title{
      @include display2;
      margin-top: -3rem;
      margin-left: -3rem;
      margin-bottom: 2rem;

      @include media-breakpoint-down(lg) {
        @include display3;
        margin-top: -1.5rem;
        margin-left:1rem;
        margin-bottom: 1rem;
      }
      @include media-breakpoint-down(sm) {
        @include h1;
      }
    }

    &-img{
      width: 120%;
      margin-left: -20%;
      margin-bottom: -20%;

      @include media-breakpoint-down(lg) {
        width: 100%;
        margin: 0;
        padding: 1rem;
      }
    }

    &-info{
      padding: 2rem;
    }

  }


  .quote-section{

    margin-top: -25rem;
    margin-bottom: 10rem;

    &-title{
      @include display4;

      @include media-breakpoint-down(lg) {
        @include display3;
        margin-top: -1.5rem;
        margin-left:1rem;
        margin-bottom: 1rem;
      }
      @include media-breakpoint-down(sm) {
        @include h1;
      }
    }

    &-img{
      width: 120%;
      margin-right: -20%;
      margin-top: -20%;
      margin-bottom: -20%;

      @include media-breakpoint-down(lg) {
        width: 100%;
        margin: 0;
        padding: 1rem;
      }
    }

    &-info{
      padding: 2rem;
    }

  }


}