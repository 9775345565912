.delivery-section{

  max-width: $st-max-width;
  justify-self: center;

  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: 10rem 0 0;

  background-image: url("/img/bg/delivery-bg.png");
  background-size: cover;
  background-position: bottom right;
  background-attachment: fixed;

  @include media-breakpoint-down(md) {
    padding-top: 5rem;
  }

  &:after{
    background-image: url('/img/misc/tri.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display:block;
    width: 100%;
    height: 31.25rem;
    content:"";
    margin-top: 10rem;
  }

  &-title{
    @include display2;
    @include media-breakpoint-down(sm) {
      @include display4;
    }

    &:after{
      content: "";
      display: inline-block;
      width: 100%;
      height: 0.2rem;
      background-color: $st-green;
    }
  }

}
