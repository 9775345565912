.materials-section{

  max-width: $st-max-width;
  justify-self: center;

  margin-left: auto;
  margin-right: auto;
  padding: 0 0 10rem;

  background-image: url("/img/bg/materials-bg.png");
  background-size: cover;
  background-position: bottom right;
  background-attachment: fixed;


  &:before{
    background-image: url('/img/misc/tri2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display:block;
    width: 100%;
    height: 31.25rem;
    content:"";
    margin-top: 10rem;
  }

  &-title{
    @include display2;

    @include media-breakpoint-down(sm) {
      @include display4;
    }

    &:after{
      content: "";
      display: inline-block;
      width: 100%;
      height: 0.2rem;
      background-color: $st-green;
    }
  }

  &-element{
    box-shadow: $box-shadow;
    border-radius: 100%;
    width: 10rem;
    height: 10rem;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    @include h3;
    color: $st-dark-alt;

  }

  .pine{background-image: url("/img/material/pine.png");}
  .larch{background-image: url("/img/material/larch.png");}
  .iroko{background-image: url("/img/material/iroko.png");}
  .oak{background-image: url("/img/material/oak.png");}



}
