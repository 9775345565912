.news-card{

  background-color: $st-dark;
  box-shadow: $box-shadow;
  border:none;
  border-bottom: 0.3125rem solid $st-dark;
  transition: all 0.25s ease-in;
  overflow: hidden;

  &:hover{
    transform: translateY(-0.3125rem);
    border-bottom: 0.3125rem solid $st-green;

    .news-img{
      transform: scale(1.1);
    }

  }

.img-col{
  overflow: hidden;

  .news-img{
    width: 100%;
    transition: all .4s ease;
  }

}

  .card-body{

    .card-title{@include h3;}
    .card-text{}
    a{text-decoration: none;}

  }

}
