.home-hero{
  max-width: $st-max-width;
  justify-self: center;

  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  padding: 5rem 0 0;

  background-image: url("/img/bg/home-hero-bg.png");
  background-size: cover;
  background-position: top right;
  background-attachment: fixed;

  @include media-breakpoint-down(md) {
    padding-top: 5rem;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 1rem;
  }

  &:after{
    background-image: url('/img/misc/tri.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display:block;
    width: 100%;
    height: 31.25rem;
    content:"";
    margin-top: 10rem;
  }

  .container{
    background-color: $st-blue;
    box-shadow:$box-shadow;
    padding: 1rem;

    .carousel-control-prev, .carousel-control-next{
      background-color: transparent;
      border: 0;
    }

    .title{
      color: white;
      @include display3;
      &:after{
        content: "";
        display: inline-block;
        width: 100%;
        height: 0.2rem;
        background-color: $st-green;
      }

      @include media-breakpoint-down(xxl) {
        @include display4;
      }

      @include media-breakpoint-down(sm) {
        @include h1;
      }

    }

    .sub-title{
      color: white;
      text-transform: initial!important;
      font-size: 1.2rem!important;
      @include lead;

      @include media-breakpoint-down(sm) {
        @include lead;
      }

    }
  }

}

.hero-section{

  max-width: $st-max-width;
  justify-self: center;

  margin-left: auto;
  margin-right: auto;
  margin-top: 7rem;
  padding: 7rem 0 0;

  background-image: url("/img/bg/home-hero-bg.png");
  background-size: cover;
  background-position: top right;
  background-attachment: fixed;

  @include media-breakpoint-down(md) {
    padding-top: 5rem;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 1rem;
  }

  &:after{
    background-image: url('/img/misc/tri.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display:block;
    width: 100%;
    height: 31.25rem;
    content:"";
    margin-top: 10rem;
  }

  .title{
    color: white;
    &:after{
      content: "";
      display: inline-block;
      width: 100%;
      height: 0.2rem;
      background-color: $st-green;
    }

    @include media-breakpoint-down(lg) {
      @include display3;
    }

    @include media-breakpoint-down(sm) {
      @include h1;
    }

  }

  .sub-title{
    color: white;
    text-transform: initial!important;
    font-size: 1.2rem!important;
    @include lead;

    @include media-breakpoint-down(sm) {
      @include lead;
    }

  }


}