// Colors

	$st-green: 		#7CC400;
	$st-green-alt: 	#3C9600;
	$st-blue: 		#1A212A;
	$st-blue-alt: 	#1A212A;
	$st-dark: 		#1D252E;
	$st-dark-alt: 	#161C22;

	$st-gray-01:	#111111;
	$st-gray-02:	#191919;
	$st-gray-03:	#4A4A4A;
	$st-gray-04:	#737373;

// Fonts

	$st-font-family:	Facundo, Arial, sans-serif;


// Grid

	$st-max-width:	1800px;



